/* Modal container */
.upcoming-matchup-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scroll if needed */
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5); /* Black with opacity */
}

/* Modal content */
.upcoming-matchup-modal-content {
    background-color: #BBCCD5;
    color: #0E1426;
    margin: 15% 15%; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #80CFFF;
    width: 80%; /* Could be more or less, depending on screen size */
    max-height: 80vh; /* Set a max height */
    overflow-y: auto; /* Enable vertical scroll if content overflows */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow */
}

/* Close button */
.upcoming-matchup-close {
    color: #0E1426;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.upcoming-matchup-close:hover,
.upcoming-matchup-close:focus {
    color: #F5424D;
    text-decoration: none;
}

/* Modal body */
.upcoming-matchup-modal-body {
    max-height: 70vh; /* Set a max height for the modal body */
    overflow-y: auto; /* Enable vertical scroll */
    padding: 10px; /* Add some padding */
}

/* Example for headings inside modal */
.upcoming-matchup-modal-body h2 {
    margin-top: 0; /* Remove default margin-top */
}

/* Matchup table */
.matchup-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

/* Header row - no borders or background */
.matchup-table-header tr {
    border: none;
    background-color: transparent;
}

.matchup-table th,
.matchup-table td {
    padding: 10px;
    text-align: center;
}

/* Team names row */
.team-names-row td {
    border: 1px solid #80CFFF;
}

.team-name {
    border-left: none;
    border-right: none;
}

.vs-column {
    width: 50px; /* Fixed width for "vs" column */
    border-left: none;
    border-right: none;
}

.matchup-table th img,
.matchup-table td img {
    max-width: 120px;
    height: auto;
}

.stats-title {
    font-weight: bold;
    background-color: #80CFFF;
    color: #0E1426;
    text-align: center;
}

.hover-row:hover {
    background-color: #E3F2FD;
    cursor: pointer;
}

/* Conditional formatting */
.higher {
    color: #155724; /* Dark green */
}

.lower {
    color: #721c24; /* Dark red */
}

.stats-category {
    background-color: #AFCDE6; /* Slightly lighter blue */
    color: #0E1426;
    text-align: center;
    font-weight: 600; /* Slightly less bold than stats-title */
}
