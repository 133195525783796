.matchVideo-container {
    padding: 20px;
    background-color: #0E1426;
    color: #BBCCD5;
}

.matchVideo-select {
    display: block;
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #80CFFF;
    background-color: white;
    color: #0E1426;
}

.matchVideo-results {
    margin-top: 20px;
}

.matchVideo-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #0E1426;
    color: #BBCCD5;
}

.matchVideo-table th, .matchVideo-table td {
    border: 1px solid #80CFFF;
    padding: 8px;
    text-align: center;
}

.matchVideo-message {
    text-align: center;
    padding: 20px;
    color: #d9534f;
    background-color: #0E1426;
    border-radius: 4px;
    margin-top: 10px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #BBCCD5;
    padding: 20px;
    padding-right: 40px; /* Extra padding to the right */
    padding-bottom: 20px; /* Extra padding to the bottom */
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column; /* Ensure the content flows vertically */
    width: 1000px; /* Adjust width to fit video and controls */
    max-width: 90%; /* Ensure it fits within the viewport */
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.video-wrapper {
    display: flex;
    flex: 1;
    margin-bottom: 20px; /* Ensure padding at the bottom */
}

.modal-video {
    flex: 1;
    max-width: 760px; /* Adjusted width to fit the modal */
    height: auto;
    border: 1px solid #ccc;
}

.clip-tools {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.clip-tools button {
    margin-bottom: 10px;
}

.annotation-tools {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.annotation {
    pointer-events: none;
}

.annotation.highlight {
    background-color: rgba(255, 255, 0, 0.3);
}

.annotation.track {
    background-color: rgba(0, 255, 0, 0.3);
}
.button.button-green {
    background-color: green;
    color: white;
}
