/* style/Core.css */
body {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;                                                
    background-color: #0E1426;
    margin: 0;
    padding: 0;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007BFF;
    color: white;
}

.button-outline {
    background-color: transparent;
    color: #007BFF;
    border: 1px solid #007BFF;
}

.input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 10px 0;
}

.logoutButton {
    background-color: #80CFFF;
    color: #0E1426;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 30px;
}

.logoutButton:hover {
    background-color: #F5424D;
    color: #BBCCD5;
}

/* CSS for downloaddata page */
/* General settings for the page container */
.downloadData-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    margin-top: 30px;
    background-color: #BBCCD5;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}

/* Styling for all select elements */
.downloadData-select {
    width: 100%;
    padding: 8px 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #80CFFF;
    background-color: white;
}

.downloadData-select[multiple] {
    height: auto;
    min-height: 100px;
    overflow-y: auto;
}

/* Styling for buttons */
.downloadData-button {
    background-color: #80CFFF;
    color: #0E1426;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.downloadData-button:hover {
    background-color: #F5424D;
}

/* List styles for selected seasons */
.downloadData-list {
    color: #0E1426;
    list-style-type: none;
    padding: 0;
}

.downloadData-item {
    padding: 8px;
    border-bottom: 1px solid #F5424D;
}

.downloadData-item:last-child {
    border-bottom: none;
}

/* Header styles */
.downloadData-header {
    color: #333;
}

/* Error and info messages */
.downloadData-message {
    padding: 10px;
    color: #d9534f;
    background-color: #0E1426;
    border-radius: 4px;
    margin-top: 10px;
}

.typing-text-dark {
    color: #0E1426;
    font-weight: bold;
    height: 30px;
}

.typing-text {
    color: #BBCCD5;
    font-weight: bold;
    height: 30px;
}

/* General settings for sticky headers, each with a different 'top' to stack correctly */

.upcomingMatchesDateHeader {
    position: sticky;
    top: 0px; /* Height of the header above */
    z-index: 1020; /* Slightly lower z-index than the header above */
    background-color: #0E1426;
    width: 100%;
    padding-top: 10px;
    height: 40px; /* Ensure this height accommodates all content in the header */
}

.upcomingMatchesCompetitionHeader {
    position: sticky;
    top: 50px; /* Adjust this value if needed to ensure it's below the date header */
    z-index: 1010; /* Lower than both the above headers */
    margin-left: 15px;
    background-color: #0E1426;
    width: calc(38% - 15px); /* Adjust width due to margin */
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.update-season-button {
    margin-left: 15px;
    padding: 5px 10px;
    font-size: 12px;
    background-color: #80CFFF;
    color: white;
    border: 2px solid #BBCCD5;
    border-radius: 3px;
    cursor: pointer;
}

.update-season-button:hover {
    background-color: #45a049;
}


/* Styles for content that should scroll normally */
.upcomingMatchesList {
    position: relative;
    margin-left: 25px;
    padding-top: 5px; /* Add padding to push content below sticky headers */
}

.upcomingMatchStatTable {
    position: relative;
    text-align: center;
    margin-left: 35px;
    margin-bottom: 20px;
    width: fit-content;
    border-collapse: collapse;
}

.upcomingMatchStatTable thead th {
    border-top: 2px solid #BBCCD5;
    border-bottom: 2px solid #BBCCD5;
}

.upcomingMatchStatTable tbody tr.home-team,
.upcomingMatchStatTable tbody tr.home-team-home {
    border-bottom: 1px solid #666;
}

.upcomingMatchStatTable tbody tr.home-team-home {
    border-bottom: 1px solid #BBCCD5;
}

.upcomingMatchStatTable tbody tr.away-team,
.upcomingMatchStatTable tbody tr.away-team-away {
    border-top: 1px solid #666;
}

.upcomingMatchStatTable tbody tr.away-team-away {
    border-bottom: 1px solid #666;
}

.upcomingMatchStatTable th,
.upcomingMatchStatTable td {
    padding: 8px;
}

.upcomingMatchStatTable tbody tr:hover {
    background-color: rgba(200, 200, 200, 0.2);
}

.matchContentWrapper {
    top: 10px; /* Only add padding to push down the matches below the sticky headers */
    position: relative;
    z-index: 1000; /* Lower than the sticky headers */
}


/* Loading page css */
.centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust this to your needs */
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotating-logo {
    animation: rotate 2s linear infinite;
    width: 200px;
    margin-bottom: calc(200px * 0.414);
    height: auto;
}

/* General settings for the page container */
.visualizations-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    margin-top: 30px;
    background-color: #BBCCD5;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}

/* Styling for all select elements */
.visualizations-select {
    width: 100%;
    padding: 8px 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    border: 1px solid #80CFFF;
    background-color: white;
}

.visualizations-select[multiple] {
    height: auto;
    min-height: 100px;
    overflow-y: auto;
}

/* Styling for buttons */
.visualizations-button {
    background-color: #80CFFF;
    color: #0E1426;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.visualizations-button:hover {
    background-color: #F5424D;
}

/* Error and info messages */
.visualizations-message {
    padding: 10px;
    color: #d9534f;
    background-color: #0E1426;
    border-radius: 4px;
    margin-top: 10px;
}

/* Modal styles */
.visualizations-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    padding-left: 100px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.5);
}

.visualizations-modal-content {
    background-color: #0E1426;
    border: 2px solid #80CFFF;
    border-radius: 8px;
    padding: 20px;

    position: relative;
    width: 80%;
    max-width: 700px;
    margin: auto;
}

.visualizations-modal-close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.visualizations-modal-close:hover {
    color: #F5424D;
}

.generateApiKey-button {
    background-color: #80CFFF;
    color: #0E1426;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.generateApiKey-key-container {
    background-color: #0E1426;
    color: #BBCCD5;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.generateApiKey-key {
    background-color: #1E2A38;
    padding: 10px;
    border-radius: 5px;
    color: #BBCCD5;
    word-break: break-all;
}

.generateApiKey-copy-button {
    background-color: #80CFFF;
    color: #0E1426;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

/* CSS for the user management */
/* Container styling */
.user-rbac-management-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    margin-top: 30px;
    background-color: #BBCCD5;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    color: #0E1426;
    text-align: center;
}

/* Table styling */
.user-rbac-management-table-responsive {
    margin-top: 20px;
}

.user-rbac-management-table-responsive table {
    width: 100%;
    background-color: #0E1426;
    color: #BBCCD5;
}

.user-rbac-management-table-responsive th,
.user-rbac-management-table-responsive td {
    padding: 10px;
    text-align: center;
    border: 1px solid #80CFFF;
}

/* Button styling */
.user-rbac-management-button {
    background-color: #80CFFF;
    color: #0E1426;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.user-rbac-management-button:hover {
    background-color: #F5424D;
}

/* Modal styling */
.user-rbac-management-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.user-rbac-management-modal-content {
    background-color: #0E1426;
    border: 2px solid #80CFFF;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    width: 80%;
    max-width: 700px;
    margin: auto;
    color: #BBCCD5;
}

.user-rbac-management-modal-close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.user-rbac-management-modal-close:hover {
    color: #F5424D;
}

/* Select styling */
.user-rbac-management-select {
    width: 100%;
    padding: 8px 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    border: 1px solid #80CFFF;
    background-color: white;
    color: #0E1426;
}

.user-rbac-management-select[multiple] {
    height: auto;
    min-height: 100px;
    overflow-y: auto;
}

/* Form styling */
.user-rbac-management-form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.user-rbac-management-form-group label {
    flex: 0 0 150px;
    margin-right: 10px;
    text-align: right;
}

.user-rbac-management-form-group .form-control {
    flex: 1;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #80CFFF;
    background-color: white;
    color: #0E1426;
}

.user-rbac-management-form-group .form-check-label {
    margin-left: 5px;
}

.centered-add-role {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.centered-add-role .form-control {
    margin-right: 10px;
}
/* Superuser row styling */
.superuser-row {
    font-weight: bold;
}

/* No roles row styling */
.no-roles-row {
    background-color: #F5424D;
    color: #0E1426;
}